export const articleMarkdown = {
  h2: ({ children }) => {
    let html = ''
    if (children.length === 0) return <h2 />
    children.forEach((i) => {
      if (typeof i == 'string') html = html + i
      if (typeof i == 'object' && i !== null) {
        if (i.type === 'a') {
          const props = i.props
          const text = props.children.reduce((prev, curr) => prev + curr, '')
          html =
            html + `<a target=${props.target} href=${props.href}>${text}</a>`
        }
      }
    })
    return <h2 dangerouslySetInnerHTML={{ __html: html }} />
  },
  h3: ({ children }) => {
    let html = ''
    if (children.length === 0) return <h3 />
    children.forEach((i) => {
      if (typeof i == 'string') html = html + i
      if (typeof i == 'object' && i !== null) {
        if (i.type === 'a') {
          const props = i.props
          const text = props.children.reduce((prev, curr) => prev + curr, '')
          html =
            html + `<a target=${props.target} href=${props.href}>${text}</a>`
        }
      }
    })
    return <h3 dangerouslySetInnerHTML={{ __html: html }} />
  },
  table: ({ children }) => {
    if (children.length === 0) return <table />
    const table = children[0]
    const thead = table.props.children[0]
    const tbody = table.props.children[1]
    const theadTr = thead.props.children
    const tbodyTr = tbody.props.children
    const theadTrHtml = theadTr.map((i) => {
      const th = i.props.children
      return `<th>${th}</th>`
    })
    const tbodyTrHtml = tbodyTr.map((i) => {
      const td = i.props.children
      return `<td>${td}</td>`
    })
    const html = `
      <table>
        <thead>
          <tr>${theadTrHtml.join('')}</tr>
        </thead>
        <tbody>
          <tr>${tbodyTrHtml.join('')}</tr>  
        </tbody>
      </table>
    `
    return <div dangerouslySetInnerHTML={{ __html: html }} />
  },

  p: ({ node, inline, className, children, ...props }) => {
    const admonitionMark = ':::'
    const markLength = admonitionMark.length

    const isAdmonition =
      children &&
      children[0] &&
      typeof children[0] === 'string' &&
      children[0].includes(admonitionMark) &&
      children[children.length - 1].includes(admonitionMark)


    if (isAdmonition) {
      const regexp = new RegExp(`^:{${markLength}}[a-z]*`, 'i')

      const admontionType = children[0]?.match(regexp)[0]?.substring(markLength)
      const title = children[0]
        .slice(admontionType.length + markLength, children[0].indexOf('\n'))
        .replace(admonitionMark, '')
        .trim()
      let content = ''

      if (children.length === 1) {
        content = children[0]
          .slice(
            admontionType.length + markLength,
            children[0].length - markLength
          )
          .replace(title, '')
          .trim()
          .split('\r\n')
          .filter((item) => item)
          .map((item) => `<p class="content">${item
            .replace(admonitionMark, '')
          }</p>`)
          .join('')
      }


      if (children.length > 1) {
        let contentList = children
          .filter((i) => i)
          .map((child, index) => {
            if (typeof child === 'string' && index === 0) {
              return child
                .slice(admontionType.length + markLength)
                .replace(title, '')
                .split('\r\n')
                .filter((item) => item)
                .map((item) => `<p class="content">${item}</p>`)
                .join('')
            }

            if (
              typeof child === 'string' &&
              child.replaceAll(' ', '').slice(0, 2) !== '\r\n'
            ) {
              return child
                .replace(admonitionMark, '')
                .split('\r\n')
                .filter((item) => item)
                .map((item, index) => {
                  if (index === 0) return `<span>${item}</span>`
                  return `<p class="content">${item}</p>`
                })
                .join('')
            }

            if (typeof child === 'string') {
              return child
                .replace(admonitionMark, '')
                .split('\r\n')
                .filter((item) => item)
                .map((item) => `<p class="content">${item}</p>`)
                .join('')
            }

            if (typeof child === 'object') {
              const props = Object.entries(child.props)
                .filter((item) => item[0] !== 'children')
                .map((i) => `${i[0]}=${i[1]}`)
                .join(' ')

              const text = child.props?.children[0]

              return `<a ${props}>${text}</a>`
            }
          })

        // Process the contentList to merge <a> tags and text with <p> tags
        contentList = contentList.reduce((acc, item, index) => {
          if (item.startsWith('<a ')) {
            const prevItem = acc[acc.length - 1]
            if (prevItem && prevItem.startsWith('<p class="content">')) {
              // Remove the closing </p> tag from the previous item
              const updatedPrevItem = prevItem.slice(0, -4)
              acc[acc.length - 1] = updatedPrevItem

              // Append the <a> tag to the previous item
              acc[acc.length - 1] += item
            } else {
              // Create a new <p> tag with the <a> tag inside
              acc.push(`<p class="content">${item}`)
            }
          } else if (typeof item === 'string' && acc.length > 0) {
            // Append the text to the previous item
            acc[acc.length - 1] += item
          } else {
            acc.push(item)
          }
          return acc
        }, [])

        // Add the closing </p> tag to the last item if needed
        const lastItem = contentList[contentList.length - 1]
        if (lastItem && !lastItem.endsWith('</p>')) {
          contentList[contentList.length - 1] += '</p>'
        }

        content = contentList.filter((i) => i).join('')
      }

      const html = `
        <div class="header">
        <span class="icon">
          ${getSVG(admontionType?.toLowerCase())}
        </span>
        <span class="title">${title}</span>
        </div>
        <div class="content-wrap">${content}</div>
        `

      return (
        <div
          className={`admonition admonition_${admontionType}`}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      )
    }
    // filename syntax is `:filename: filename.ext`. make it a p element with a  
    // class of `filename`
    if (children && children[0]
      && typeof children[0] === 'string'
      && children[0].startsWith('::filename::')) {
      const [filename, link] = children[0].replace('::filename::', '').trim().split(' ')
      if (link) {
        return (
          <p className="filename" {...props}>
            <a href={link}>{filename}</a>
          </p>
        )
      } else {
        return (
          <p className="filename" {...props}>
            {filename}
          </p>
        )
      }
    }

    return (
      <p className={className} {...props}>
        {children}
      </p>
    )
  },
}

function getSVG(type) {
  let svg = null
  switch (true) {
  case type === 'note': {
    svg = `
      <?xml version="1.0" encoding="UTF-8"?>
      <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>47</title>
          <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.900000036">
              <g id="Icons-Copy" transform="translate(-1000.000000, -60.000000)">
                  <g id="Group-2-Copy" transform="translate(840.000000, 50.000000)">
                      <g id="47" transform="translate(160.000000, 10.000000)">
                          <rect id="Rectangle-56" x="0" y="0" width="20" height="20"></rect>
                          <g id="note_alt_black_24dp" fill="#000000" fill-rule="nonzero">
                              <path d="M10,20 C15.5228475,20 20,15.5228475 20,10 C20,4.4771525 15.5228475,0 10,0 C4.4771525,0 0,4.4771525 0,10 C0,15.5228475 4.4771525,20 10,20 Z M7.1,15 L5,15 L5,12.86 L10.96,6.9 L13.08,9.02 L7.1,15 Z M14.85,7.27 L13.79,8.33 L11.67,6.21 L12.73,5.15 C12.93,4.95 13.24,4.95 13.44,5.15 L14.85,6.56 C15.05,6.76 15.05,7.07 14.85,7.27 Z" id="Shape"></path>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
      `
    break
  }
  case type === 'abstract': {
    svg = `<?xml version="1.0" encoding="UTF-8"?>
      <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>48</title>
          <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.900000036">
              <g id="Icons-Copy" transform="translate(-1000.000000, -100.000000)">
                  <g id="Group-2-Copy" transform="translate(840.000000, 50.000000)">
                      <g id="Group-2-Copy-3" transform="translate(160.000000, 10.000000)">
                          <g id="48" transform="translate(0.000000, 40.000000)">
                              <rect id="Rectangle-56" x="0" y="0" width="20" height="20"></rect>
                              <path d="M17,2 L12.82,2 C12.4,0.84 11.3,0 10,0 C8.7,0 7.6,0.84 7.18,2 L3,2 C1.9,2 1,2.9 1,4 L1,18 C1,19.1 1.9,20 3,20 L17,20 C18.1,20 19,19.1 19,18 L19,4 C19,2.9 18.1,2 17,2 Z M10,2 C10.55,2 11,2.45 11,3 C11,3.55 10.55,4 10,4 C9.45,4 9,3.55 9,3 C9,2.45 9.45,2 10,2 Z M12,16 L5,16 L5,14 L12,14 L12,16 Z M15,12 L5,12 L5,10 L15,10 L15,12 Z M15,8 L5,8 L5,6 L15,6 L15,8 Z" id="Shape" fill="#000000" fill-rule="nonzero"></path>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>`
    break
  }
  case type === 'info': {
    svg = `<?xml version="1.0" encoding="UTF-8"?>
      <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>49</title>
          <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.900000036">
              <g id="Icons-Copy" transform="translate(-1000.000000, -140.000000)">
                  <g id="Group-2-Copy" transform="translate(840.000000, 50.000000)">
                      <g id="Group-2-Copy-3" transform="translate(160.000000, 10.000000)">
                          <g id="49" transform="translate(0.000000, 80.000000)">
                              <rect id="Rectangle-56" x="0" y="0" width="20" height="20"></rect>
                              <path d="M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 10,0 Z M11,15 L9,15 L9,9 L11,9 L11,15 Z M11,7 L9,7 L9,5 L11,5 L11,7 Z" id="Shape" fill="#000000" fill-rule="nonzero"></path>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>`
    break
  }
  case type === 'tip': {
    svg = `<?xml version="1.0" encoding="UTF-8"?>
      <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>50</title>
          <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.900000036">
              <g id="Icons-Copy" transform="translate(-1000.000000, -180.000000)">
                  <g id="Group-2-Copy" transform="translate(840.000000, 50.000000)">
                      <g id="Group-2-Copy-3" transform="translate(160.000000, 10.000000)">
                          <g id="50" transform="translate(0.000000, 120.000000)">
                              <rect id="Rectangle-56" x="0" y="0" width="20" height="20"></rect>
                              <path d="M7.86666667,18 L12.1333333,18 C12.1333333,19.1 11.1733333,20 10,20 C8.82666667,20 7.86666667,19.1 7.86666667,18 Z M5.73333333,17 L14.2666667,17 L14.2666667,15 L5.73333333,15 L5.73333333,17 Z M18,7.5 C18,11.32 15.1626667,13.36 13.9786667,14 L6.02133333,14 C4.83733333,13.36 2,11.32 2,7.5 C2,3.36 5.584,0 10,0 C14.416,0 18,3.36 18,7.5 Z" id="Shape" fill="#000000" fill-rule="nonzero"></path>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>`
    break
  }
  case type === 'success': {
    svg = `<?xml version="1.0" encoding="UTF-8"?>
      <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>51</title>
          <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.900000036">
              <g id="Icons-Copy" transform="translate(-1000.000000, -220.000000)">
                  <g id="Group-2-Copy" transform="translate(840.000000, 50.000000)">
                      <g id="Group-2-Copy-3" transform="translate(160.000000, 10.000000)">
                          <g id="51" transform="translate(0.000000, 160.000000)">
                              <rect id="Rectangle-56" x="0" y="0" width="20" height="20"></rect>
                              <path d="M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 10,0 L10,0 Z M8,15 L3,10 L4.41,8.59 L8,12.17 L15.59,4.58 L17,6 L8,15 L8,15 Z" id="Shape" fill="#000000"></path>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>`
    break
  }
  case type === 'question': {
    svg = `<?xml version="1.0" encoding="UTF-8"?>
      <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>52</title>
          <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.900000036">
              <g id="Icons-Copy" transform="translate(-1000.000000, -260.000000)">
                  <g id="Group-2-Copy" transform="translate(840.000000, 50.000000)">
                      <g id="Group-2-Copy-3" transform="translate(160.000000, 10.000000)">
                          <g id="52" transform="translate(0.000000, 200.000000)">
                              <rect id="Rectangle-56" x="0" y="0" width="20" height="20"></rect>
                              <path d="M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 10,0 L10,0 Z M11,17 L9,17 L9,15 L11,15 L11,17 L11,17 Z M13.07,9.25 L12.17,10.17 C11.45,10.9 11,11.5 11,13 L9,13 L9,12.5 C9,11.4 9.45,10.4 10.17,9.67 L11.41,8.41 C11.78,8.05 12,7.55 12,7 C12,5.9 11.1,5 10,5 C8.9,5 8,5.9 8,7 L6,7 C6,4.79 7.79,3 10,3 C12.21,3 14,4.79 14,7 C14,7.88 13.64,8.68 13.07,9.25 L13.07,9.25 Z" id="Shape" fill="#000000"></path>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>`
    break
  }
  case type === 'warning': {
    svg = `<?xml version="1.0" encoding="UTF-8"?>
      <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>53</title>
          <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.900000036">
              <g id="Icons-Copy" transform="translate(-1000.000000, -300.000000)">
                  <g id="Group-2-Copy" transform="translate(840.000000, 50.000000)">
                      <g id="Group-2-Copy-3" transform="translate(160.000000, 10.000000)">
                          <g id="53" transform="translate(0.000000, 240.000000)">
                              <rect id="Rectangle-56" x="0" y="0" width="20" height="20"></rect>
                              <path d="M0,19 L20,19 L10,2 L0,19 L0,19 Z M11,17 L9,17 L9,15 L11,15 L11,17 L11,17 Z M11,13 L9,13 L9,9 L11,9 L11,13 L11,13 Z" id="Shape" fill="#000000"></path>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>`
    break
  }
  case type === 'failure': {
    svg = `<?xml version="1.0" encoding="UTF-8"?>
      <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>54</title>
          <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.900000036">
              <g id="Icons-Copy" transform="translate(-1000.000000, -340.000000)">
                  <g id="Group-2-Copy" transform="translate(840.000000, 50.000000)">
                      <g id="Group-2-Copy-3" transform="translate(160.000000, 10.000000)">
                          <g id="54" transform="translate(0.000000, 280.000000)">
                              <rect id="Rectangle-56" x="0" y="0" width="20" height="20"></rect>
                              <path d="M10,0 C4.47,0 0,4.47 0,10 C0,15.53 4.47,20 10,20 C15.53,20 20,15.53 20,10 C20,4.47 15.53,0 10,0 Z M15,13.59 L13.59,15 L10,11.41 L6.41,15 L5,13.59 L8.59,10 L5,6.41 L6.41,5 L10,8.59 L13.59,5 L15,6.41 L11.41,10 L15,13.59 Z" id="Shape" fill="#000000" fill-rule="nonzero"></path>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>`
    break
  }
  case type === 'danger': {
    svg = `<?xml version="1.0" encoding="UTF-8"?>
      <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>55</title>
          <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.900000036">
              <g id="Icons-Copy" transform="translate(-1000.000000, -380.000000)">
                  <g id="Group-2-Copy" transform="translate(840.000000, 50.000000)">
                      <g id="Group-2-Copy-3" transform="translate(160.000000, 10.000000)">
                          <g id="55" transform="translate(0.000000, 320.000000)">
                              <rect id="Rectangle-56" x="0" y="0" width="20" height="20"></rect>
                              <polygon id="Shape" fill="#000000" points="5 0 5 11 8 11 8 20 15 8 11 8 15 0"></polygon>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>`
    break
  }
  case type === 'bug': {
    svg = `<?xml version="1.0" encoding="UTF-8"?>
      <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>56</title>
          <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.900000036">
              <g id="Icons-Copy" transform="translate(-1000.000000, -420.000000)">
                  <g id="Group-2-Copy" transform="translate(840.000000, 50.000000)">
                      <g id="Group-2-Copy-3" transform="translate(160.000000, 10.000000)">
                          <g id="56" transform="translate(0.000000, 360.000000)">
                              <rect id="Rectangle-56" x="0" y="0" width="20" height="20"></rect>
                              <path d="M18,6 L15.19,6 C14.74,5.22 14.12,4.55 13.37,4.04 L15,2.41 L13.59,1 L11.42,3.17 C10.96,3.06 10.49,3 10,3 C9.51,3 9.04,3.06 8.59,3.17 L6.41,1 L5,2.41 L6.62,4.04 C5.88,4.55 5.26,5.22 4.81,6 L2,6 L2,8 L4.09,8 C4.04,8.33 4,8.66 4,9 L4,10 L2,10 L2,12 L4,12 L4,13 C4,13.34 4.04,13.67 4.09,14 L2,14 L2,16 L4.81,16 C5.85,17.79 7.78,19 10,19 C12.22,19 14.15,17.79 15.19,16 L18,16 L18,14 L15.91,14 C15.96,13.67 16,13.34 16,13 L16,12 L18,12 L18,10 L16,10 L16,9 C16,8.66 15.96,8.33 15.91,8 L18,8 L18,6 L18,6 Z M12,14 L8,14 L8,12 L12,12 L12,14 L12,14 Z M12,10 L8,10 L8,8 L12,8 L12,10 L12,10 Z" id="Shape" fill="#000000"></path>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>`
    break
  }
  case type === 'example': {
    svg = `<?xml version="1.0" encoding="UTF-8"?>
      <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>57</title>
          <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.900000036">
              <g id="Icons-Copy" transform="translate(-1000.000000, -460.000000)">
                  <g id="Group-2-Copy" transform="translate(840.000000, 50.000000)">
                      <g id="Group-2-Copy-3" transform="translate(160.000000, 10.000000)">
                          <g id="57" transform="translate(0.000000, 400.000000)">
                              <rect id="Rectangle-56" x="0" y="0" width="20" height="20"></rect>
                              <path d="M17.7991988,16.4 L11.9991988,8.67 L11.9991988,4.5 L13.3491988,2.81 C13.6091988,2.48 13.3791988,2 12.9591988,2 L7.03919877,2 C6.61919877,2 6.38919877,2.48 6.64919877,2.81 L7.99919877,4.5 L7.99919877,8.67 L2.19919877,16.4 C1.70919877,17.06 2.17919877,18 2.99919877,18 L16.9991988,18 C17.8191988,18 18.2891988,17.06 17.7991988,16.4 Z" id="Path" fill="#000000" fill-rule="nonzero"></path>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>`
    break
  }
  case type === 'quote': {
    svg = `<?xml version="1.0" encoding="UTF-8"?>
      <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>58</title>
          <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.900000036">
              <g id="Icons-Copy" transform="translate(-1000.000000, -500.000000)">
                  <g id="Group-2-Copy" transform="translate(840.000000, 50.000000)">
                      <g id="Group-2-Copy-3" transform="translate(160.000000, 10.000000)">
                          <g id="58" transform="translate(0.000000, 440.000000)">
                              <rect id="Rectangle-56" x="0" y="0" width="20" height="20"></rect>
                              <path d="M4,15 L7,15 L9,11 L9,5 L3,5 L3,11 L6,11 L4,15 Z M12,15 L15,15 L17,11 L17,5 L11,5 L11,11 L14,11 L12,15 Z" id="Shape" fill="#000000" fill-rule="nonzero"></path>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>`
    break
  }
  }

  return svg
}
